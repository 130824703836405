<template>
	<div class="register">
		<div class="box-card">
			<div class="register-title">用户注册</div>
			<div class="register-account">
				<el-form :model="registerForm" :rules="registerRules" ref="registerRef" label-width="80px" label-position="right" show-message>
					<el-form-item label="公司名称" prop="username"><el-input v-model="registerForm.username" placeholder="请输入公司名称"></el-input></el-form-item>
					<el-form-item label="联系人" prop="nickname"><el-input v-model="registerForm.nickname" placeholder="请输入联系人"></el-input></el-form-item>
					<el-form-item label="密码" prop="password"><el-input v-model="registerForm.password" placeholder="请输入密码" type="password"></el-input></el-form-item>
					<el-form-item label="确认密码" prop="checkPass">
						<el-input v-model="registerForm.checkPass" placeholder="请输入确认密码" type="password"></el-input>
					</el-form-item>
					
					<el-form-item label="手机号" prop="mobile"><el-input v-model="registerForm.mobile" placeholder="请输入您的手机号"></el-input></el-form-item>
					
					<el-form-item label="地址" prop="full_address">
						<el-select v-model="registerForm.province_id" placeholder="请选择省" @change="changeProvice(registerForm.province_id)">
							<el-option v-for="option in province" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
						</el-select>
						<el-select v-model="registerForm.city_id" placeholder="请选择市" @change="changeCity(registerForm.city_id)">
							<el-option v-for="option in city" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
						</el-select>
						<el-select v-model="registerForm.district_id" placeholder="请选择区/县" @change="changeDistrict(registerForm.district_id)">
							<el-option v-for="option in district" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="详细地址" prop="address">
						<el-input v-model.trim="registerForm.address" autocomplete="off" placeholder="定位到小区、街道、写字楼"></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="code">
						<el-input v-model="registerForm.code" placeholder="请输入验证码" maxlength="4">
							<template slot="append">
								<img :src="captcha.img" mode class="captcha" @click="getCode" />
							</template>
						</el-input>
					</el-form-item>
				</el-form>
				<div class="xy" @click="check">
					<div class="xy-wrap">
						<div class="iconfont" :class="ischecked ? 'iconxuanze-duoxuan' : 'iconxuanze'"></div>
						<div class="content">
							阅读并同意
							<b @click.stop="getAggrement">《服务协议》</b>
						</div>
					</div>
					<div class="toLogin" @click="toLogin">已有账号，立即登录</div>
				</div>
				<el-button @click="register">立即注册</el-button>
			</div>
			<el-dialog :title="agreement.title" :visible.sync="aggrementVisible" width="60%" :before-close="aggrementClose" :lock-scroll="false" center>
				<div v-html="agreement.content" class="xyContent"></div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { getRegisiterAggrement, register, registerConfig } from '@/api/auth/register';
import { captcha } from '@/api/website';
import { getArea } from "@/api/address"
export default {
	name: 'register',
	components: {},
	data() {
		let self = this
		var isMobile = (rule, value, callback) => {
		    if (!value) {
		        return callback(new Error("手机号不能为空"))
		    } else {
		        const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
		
		        if (reg.test(value)) {
		            callback()
		        } else {
		            callback(new Error("请输入正确的手机号"))
		        }
		    }
		}
		
		var fullAddress = (rule, value, callback) => {
		    if (self.registerForm.province_id) {
		        if (self.registerForm.city_id) {
		            if (self.registerForm.district_id) {
		                return callback()
		            } else {
		                return callback(new Error("请选择区/县"))
		            }
		        } else {
		            return callback(new Error("请选择市"))
		        }
		    } else {
		        return callback(new Error("请选择省"))
		    }
		}
		
		var checkPassValidata = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.registerForm.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		var passwordValidata = function(rule, value, callback) {
			let regConfig = self.registerConfig;
			if (!value) {
				return callback(new Error('请输入密码'));
			} else {
				if (regConfig.pwd_len > 0) {
					if (value.length < regConfig.pwd_len) {
						return callback(new Error('密码长度不能小于' + regConfig.pwd_len + '位'));
					} else {
						callback();
					}
				} else {
					if (regConfig.pwd_complexity != '') {
						let passwordErrorMsg = '密码需包含',
							reg = '';
						if (regConfig.pwd_complexity.indexOf('number') != -1) {
							reg += '(?=.*?[0-9])';
							passwordErrorMsg += '数字';
						} else if (regConfig.pwd_complexity.indexOf('letter') != -1) {
							reg += '(?=.*?[a-z])';
							passwordErrorMsg += '、小写字母';
						} else if (regConfig.pwd_complexity.indexOf('upper_case') != -1) {
							reg += '(?=.*?[A-Z])';
							passwordErrorMsg += '、大写字母';
						} else if (regConfig.pwd_complexity.indexOf('symbol') != -1) {
							reg += '(?=.*?[#?!@$%^&*-])';
							passwordErrorMsg += '、特殊字符';
						} else {
							reg += '';
							passwordErrorMsg += '';
						}

						if (reg.test(value)) {
							return callback(new Error(passwordErrorMsg));
						} else {
							callback();
						}
					}
				}
			}
		};
		return {
			registerForm: {
				username: '',
				nickname: '',
				password: '',
				checkPass: '',
				mobile:'',
				code: '',
				province_id: "",
				city_id: "",
				district_id: "",
				address: "",
				full_address: "",
			},
			registerRules: {
				username: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
				nickname: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
				password: [
					{
						required: true,
						validator: passwordValidata,
						trigger: 'blur'
					}
				],
				checkPass: [{ required: true, validator: checkPassValidata, trigger: 'blur' }],
				mobile: [{ required: true, validator: isMobile, trigger: "blur" }],
				address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
				full_address: [{ required: true, validator: fullAddress, trigger: "blur" }],
				code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
			},
			province: [],
			city: [],
			district: [],
			pickerValueArray: [],
			multiIndex: [0, 0, 0],
			isInitMultiArray: false,
			// 是否加载完默认地区
            isLoadDefaultAreas: false,
            loading: true,
			yes: true,			
			ischecked: false,
			agreement: '',
			aggrementVisible: false,
			captcha: {
				// 验证码
				id: '',
				img: ''
			},
			registerConfig: {}
		};
	},
	created() {
		this.getCode();
		this.regisiterAggrement();
		this.getRegisterConfig();
		console.log(11);
		this.getDefaultAreas(0, {
		    level: 0
		})
	},
	computed: {
	    pickedArr() {
	        // 进行初始化
	        if (this.isInitMultiArray) {
	            return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
	        }
	        return [this.pickerValueArray[0], this.city, this.district]
	    }
	},
	methods: {
		/**
		 * 改变省
		 */
		changeProvice(id) {
		    this.getAreas(id, data => (this.city = data))
		    let obj = {}
		    obj = this.province.find(item => {
		        //这里的province就是上面遍历的数据源
		        return item.id === id //筛选出匹配数据
		    })
		    this.registerForm.city_id = ""
		    this.registerForm.district_id = ""
		    this.registerForm.full_address = obj.name // 设置选中的地址
		},
		/**
		 * 改变市
		 */
		changeCity(id) {
		    this.getAreas(id, data => (this.district = data))
		    let obj = {}
		    obj = this.city.find(item => {
		        //这里的province就是上面遍历的数据源
		        return item.id === id //筛选出匹配数据
		    })
		    this.registerForm.district_id = ""
		    this.registerForm.full_address = this.registerForm.full_address + "-" + obj.name
		},
		/**
		 * 改变区
		 */
		changeDistrict(id) {
		    let obj = {}
		    obj = this.district.find(item => {
		        //这里的province就是上面遍历的数据源
		        return item.id === id //筛选出匹配数据
		    })
		    this.registerForm.full_address = this.registerForm.full_address + "-" + obj.name
		},
		// 异步获取地区
		getAreas(pid, callback) {
		    getArea({
		        pid: pid
		    })
		        .then(res => {
		            if (res.code == 0) {
		                var data = []
		                res.data.forEach((item, index) => {
		                    data.push(item)
		                })
		                if (callback) callback(data)
		            }
		        })
		        .catch(err => {})
		},
		
		/**
		 * 获取省市区列表
		 */
		getDefaultAreas(pid, obj) {
			console.log(22);
		    getArea({
		        pid: pid
		    })
		        .then(res => {
					console.log(res);
		            if (res.code == 0) {
		                var data = []
		                var selected = undefined
		                res.data.forEach((item, index) => {
		                    if (obj != undefined) {
		                        if (obj.level == 0 && obj.province_id != undefined) {
		                            selected = obj.province_id
		                        } else if (obj.level == 1 && obj.city_id != undefined) {
		                            selected = obj.city_id
		                        } else if (obj.level == 2 && obj.district_id != undefined) {
		                            selected = obj.district_id
		                        }
		                    }
		
		                    if (selected == undefined && index == 0) {
		                        selected = item.id
		                    }
		                    data.push(item)
		                })
		
		                this.pickerValueArray[obj.level] = data
		                if (obj.level + 1 < 3) {
		                    obj.level++
		                    this.getDefaultAreas(selected, obj)
		                } else {
		                    this.isInitMultiArray = true
		                    this.isLoadDefaultAreas = true
		                }
						
						console.log(this.pickerValueArray);
		                this.province = this.pickerValueArray[0]
						console.log(this.province);
		            }
		            this.loading = false
		        })
		        .catch(err => {
		            this.loading = false
		        })
		},
		
		handleValueChange(e) {
		    // 结构赋值
		    let [index0, index1, index2] = e.detail.value
		    let [arr0, arr1, arr2] = this.pickedArr
		    let address = [arr0[index0], arr1[index1], arr2[index2]]
		
		    this.registerForm.full_address = ""
		    for (let i = 0; i < address.length; i++) {
		        if (this.registerForm.full_address) {
		            this.registerForm.full_address = this.registerForm.full_address + "-" + address[i].name
		        } else {
		            this.registerForm.full_address = this.registerForm.full_address + address[i].name
		        }
		    }
		},
		check() {
			this.ischecked = !this.ischecked;
		},
		toLogin() {
			this.$router.push('/login');
		},
		//  获取注册配置
		getRegisterConfig() {
			registerConfig()
				.then(res => {
					if (res.code >= 0) {
						this.registerConfig = res.data.value;
						if (this.registerConfig.register == '') {
							this.$message({
								message: '平台未启用注册',
								type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push('/');
								}
							});
						}
					}
				})
				.catch(err => {
					console.log(err.message)
				});
		},
		// 注册
		register() {
			this.$refs.registerRef.validate(valid => {
				if (valid) {
					if (!this.ischecked) {
						return this.$message({
							message: '请先阅读协议并勾选',
							type: 'warning'
						});
					}
					var data = {
						username: this.registerForm.username.trim(),
						nickname: this.registerForm.nickname,
						password: this.registerForm.password,
						mobile: this.registerForm.mobile,
						province_id: this.registerForm.province_id,
						city_id: this.registerForm.city_id,
						district_id: this.registerForm.district_id,
						address: this.registerForm.address,
						full_address: this.registerForm.full_address,
					};
					
					if (this.captcha.id != '') {
						data.captcha_id = this.captcha.id;
						data.captcha_code = this.registerForm.code;
					}
					// console.log(data);
					this.$store
						.dispatch('member/register_token', data)
						.then(res => {
							if (res.code >= 0) {
								this.$router.push('/member/index');
							}
						})
						.catch(err => {
							this.$message.error(err.message);
							this.getCode();
						});
				} else {
					return false;
				}
			});
		},
		aggrementClose() {
			this.aggrementVisible = false;
		},
		// 获取协议
		regisiterAggrement() {
			getRegisiterAggrement()
				.then(res => {
					if (res.code >= 0) {
						this.agreement = res.data;
					}
				})
				.catch(err => {
					console.log(err.message)
				});
		},
		getAggrement() {
			this.aggrementVisible = true;
		},
		// 获取验证码
		getCode() {
			captcha({ captcha_id: 'this.captcha.id' })
				.then(res => {
					if (res.code >= 0) {
						this.captcha = res.data;
						this.captcha.img = this.captcha.img.replace(/\r\n/g, '');
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		}
	}
};
</script>
<style lang="scss" scoped>
.register {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0;
}
.box-card {
	width: 600px;
	margin: 0 auto;
	display: flex;
	background-color: #ffffff;
	padding: 0 30px 30px 30px;
	flex-direction: column;

	.register-title {
		border-bottom: 1px solid #f1f1f1;
		text-align: left;
		margin-bottom: 20px;
		font-size: 16px;
		color: $base-color;
		padding: 10px 0;
	}
	.register-account {
		width: 100%;
		text-align: center;
	}
	.code {
		width: 80%;
		text-align: left;
	}
	.el-form {
		margin: 0 30px;
		.captcha {
			vertical-align: top;
			max-width: inherit;
			max-height: 38px;
			line-height: 38px;
			cursor: pointer;
		}
	}
	.xyContent {
		height: 600px;
		overflow-y: scroll;
	}
	.xy {
		margin-left: 110px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		margin-right: 30px;
		.toLogin {
			cursor: pointer;
		}
		.xy-wrap {
			display: flex;
			align-items: center;
			font-size: $ns-font-size-base;
			cursor: pointer;
			.iconfont {
				display: flex;
				align-content: center;
			}
			.content {
				margin-left: 3px;
				b {
					color: $base-color;
				}
			}
		}
		.iconxuanze-duoxuan {
			color: $base-color;
		}
	}
	.el-button {
		margin-top: 20px;
		background-color: $base-color;
		color: #ffffff;
		width: calc(100% - 60px);
	}
	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	
	.el-card.is-always-shadow,
	.el-card.is-hover-shadow:focus,
	.el-card.is-hover-shadow:hover {
	    box-shadow: unset;
	}
	
	.el-card {
	    border: 0;
	}
	
	.ns-len-input {
	    width: 350px;
	}
	
	.el-select {
		width: 140px;
	    margin-right: 10px;
	}
}
</style>
